.Banner img{
    width: 100%;
    object-fit: cover;
    height: 65vh;
}

@media only screen and (max-width: 540px) {
    .Banner img{
        height: 35vh;
    }
}