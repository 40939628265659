.about {
  width: 100%;
  background-color: black;
  padding: 4rem 0 2rem 0;
}

.about-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.about-main-peoples{
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.about-head h1 {
  text-align: center;
  color: #e7caa1;
  font-family: "Roboto", Sans-serif;
  font-size: 31px;
  font-weight: 600;
  text-transform: uppercase;
}

.about-icon span .seperator:before {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2.4px dashed #8aff74;
}

.about-icon span .seperator:after {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2.4px dashed #8aff74;
}

.about-icon span .seperator {
  width: 250px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.about-icon span svg {
  font-size: 20px;
}

.about-icon span {
  text-align: center;
  padding-block-start: 2px;
  padding-block-end: 2px;
}

.about-icon {
  color: #8aff74;
}

.about-image img {
  width: 100%;
  object-fit: cover;
  padding: 20px 0px 10px 0px;
}

.about-detail {
  padding: 4rem 0rem;
}

.about-detail h2 {
  text-align: center;
  color: #e7caa1;
  font-family: "Ruluko", Sans-serif;
  font-size: 35px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.about-detail h4 {
  text-align: center;
  color: #e7caa1;
  font-family: "Ruluko", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  padding-bottom: 38px;
  margin-bottom: 20px;
}

.about-detail p {
  text-align: justify;
  color: #e7caa1;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
}

.about-detail span {
  text-align: justify;
  color: #e7caa1;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 22px;
}

.about-our-people h2 {
  text-align: center;
  color: #EECD89;
  font-family: "Ruluko", Sans-serif;
  font-size: 31px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 40px;
}

.people-box {
  display: flex;
  padding: 30px 0px;
}

.people-box-image {
  width: 16%;
  padding: 10px;
}

.people-box img {
  width: 100%;
  border-radius:50%;
  object-fit: cover;
  height: auto;
}

.people-box-detail {
  width: 84%;
  padding: 10px;
}
.people-box-detail h3{
  margin-bottom: 20px;
  color: #E7CAA1;
  font-family: "Ruluko", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
}

.people-box-detail h3 a{
  color: #E7CAA1;
  /* font-family: "Ruluko", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase; */
  text-decoration: none;
}
.people-box-detail p{
  text-align: justify;
    color: #E7CAA1;
    font-family: "Ruluko", Sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
}

.people-box-button a p{
  font-family: "Ruluko", Sans-serif;
    font-weight: 500;
    fill: #000000;
    color: #000000;
    background-color: #DAC78F;
    padding: 12px 24px;
    font-size: 15px;
    border-radius: 3px;
    margin: 0;
}
.people-box-button{
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.people-box-button a{
  text-decoration: none;
  
}

@media screen and (min-width: 1200px) {
  .about-main{
    max-width: 1140px;
  }
  .about-main-peoples {
    max-width: 1200px;
  }
}

@media only screen and (max-width: 540px) {
.people-box {
  flex-wrap: wrap;
}

.people-box-image {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.people-box-image img{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.people-box-detail{
  text-align: center;
  width: 100%;
  padding: 10px 0px 0px;
}
}