.TermCond {
  width: 100%;
  background-color: #000000;
  padding: 4rem 0;
}

.TermCond-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.TermCond-box{
    margin-top: 2rem;
}
.TermCond-image img{
    width: 100%;
    object-fit: cover;
}
.TermCond-box h2 {
    color: #ba8137;
    font-family: "Ruluko", Sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-transform: none;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    margin-bottom: 20px;
    text-align: center;
}
.TermCond-box h4 {
  color: #ba8137;
  font-family: "Ruluko", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  text-transform: none;
  margin-bottom: 20px;
}
.TermCond-box p {
  text-align: justify;
  color: #e7caa1;
  font-family: "Ruluko", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.TermCond-box p span{
  text-align: justify;
  color: #e7caa1;
  font-family: "Ruluko", Sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.TermCond-box ul{
    padding: 0;
}
.TermCond-box ul li{
    display: flex;
    align-items: flex-start;
}
.TermCond-box ul li svg{
    color: #ba8137;
    font-size: 24px;
    margin-right: 8px;
}

@media screen and (min-width: 1200px) {
  .TermCond-main {
    max-width: 1140px;
  }
}
