.blog {
  background-color: #111111;
  font-family: "Ruluko", Sans-serif;
}

.blog-box {
  padding: 20px 0px;
  background-color: #111111;
}
.blog-box2 {
  padding: 20px 0px;
  background-color: #111111;
}

.blog-item {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 10px 30px 10px;
}

.blog-body {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.blog-banner img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.blog-head h1 {
  text-align: center;
  color: #bca57f;
  font-size: 31px;
  font-weight: 600;
  text-transform: uppercase;
  /* margin-top: 50px; */
}

.blog-icon span .seperator:before {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2.4px dashed #8aff74;
}

.blog-icon span .seperator:after {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2.4px dashed #8aff74;
}

.blog-icon span .seperator {
  width: 250px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.blog-icon span svg {
  font-size: 20px;
}

.blog-icon span {
  text-align: center;
  padding-block-start: 2px;
  padding-block-end: 2px;
}

.blog-icon {
  color: #8aff74;
}

.blog-overview {
  display: flex;
  gap: 2rem;
}

.blog-body-left {
  width: 65%;
  background-color: black;
  height: 100%;
}
.blog-body-detail {
  padding: 20px;
}
.blog-body-detail h5 {
  margin-top: 20px;
  text-align: justify;
  color: #b98f4b;
  font-size: 20px;
  font-weight: 400;
}
.blog-body-detail img {
  width: 100%;
  object-fit: cover;
  margin: 2rem 0px 0px 0;
}

.blog-body-image img {
  width: 100%;
}

.blog-body h4 {
  color: #b98f4b;
  font-size: 23px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.blog-overview .blog-body-left h3 {
  margin-top: 20px;
  text-align: justify;
  color: #b98f4b;
  font-size: 23px;
  font-weight: 400;
}

.blog-overview .blog-body-left h6 {
  text-align: justify;
  color: #bca57f;
  font-size: 13px;
  font-weight: 400;
  padding-bottom: 10px;
  border-bottom: 1px solid #241d13;
}
.blog-overview .blog-body-left p {
  text-align: justify;
  color: #bca57f;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
  margin: 0;
}
.blog-overview .blog-body-left .body-left-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.blog-overview .blog-body-left .body-left-button a {
  text-decoration: none;
  background-color: #b98f4b;
  color: black;
  border-radius: 3px;
  text-align: center;
  padding: 12px 24px;
  font-size: 15px;
  line-height: 1;
  cursor: pointer;
  transition: all 0.3s;
}
.blog-overview .blog-body-left .body-left-button a:hover {
  background-color: #f8dea0;
}

.blog-body-right {
  width: 35%;
}

.blog-body-image img {
  width: 100%;
  height: 70vh;
  object-fit: cover;
}

.blogs-items-card a {
  display: flex;
  padding: 10px;
  gap: 10px;
  border-bottom: 1px solid #241d13;
  text-decoration: none;
}
.blogs-items-card a:hover {
  background-color: #f8dea0;
}
.blogs-items-card a:hover h6,
.blogs-items-card a:hover p {
  color: black;
}
.blogs-items-card img {
  width: 40%;
  object-fit: cover;
  aspect-ratio: 3 / 2;
 /* height: 100px; */
}

.blog-card-desc h6 {
  color: #b98f4b;
  font-size: 15px;
  font-weight: 400;
}
.blog-card-desc p {
  color: #bca57f;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
}
@media screen and (min-width: 1200px) {
  .blog-body,
  .blog-item {
    max-width: 1140px;
  }
}

@media only screen and (max-width: 540px) {
  .blog-overview {
    flex-wrap: wrap;
  }

  .blog-body-left {
    width: 100%;
  }

  .blog-body-right {
    width: 100%;
  }

  .blog-body {
    padding: 0;
  }

  .blog-body-image img {
    height: 30vh;
  }
  .blog-banner img {
    height: 30vh;
  }
  .blog-overview .blog-body-left .body-left-button a {
    width: 100%;
  }
}
