.HotelDetail {
  background-color: black;
}

.hotel-detail-box {
  /* padding: 20px 0px; */
  background-color: black;
}
.hotel-detail-box2 {
  padding: 20px 0px;
  background-color: #111111;
}

.hotel-detail-item {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 10px 30px 10px;
}

.hotel-detail-body {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.hotel-detail-banner img {
  width: 100%;
  height: 60vh;
  object-fit: cover;
}

.hoteldetail-head h1 {
  text-align: center;
  color: #bca57f;
  font-family: "Ruluko", Sans-serif;
  font-size: 24px;
  font-weight: 600;
  /* text-transform: uppercase; */
  margin-top: 50px;
}
.hoteldetail-head h4 {
  text-align: center;
  color: #b98f4b;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.hoteldetail-head h5 {
  text-align: center;
  color: #8aff74;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
}

.hoteldetail-icon span .seperator:before {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2.4px dashed #8aff74;
}

.hoteldetail-icon span .seperator:after {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 2.4px dashed #8aff74;
}

.hoteldetail-icon span .seperator {
  width: 250px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.hoteldetail-icon span svg {
  font-size: 20px;
}

.hoteldetail-icon span {
  text-align: center;
  padding-block-start: 2px;
  padding-block-end: 2px;
}

.hoteldetail-icon {
  color: #8aff74;
}

.hotel-overview {
  display: flex;
  gap: 1rem;
}

.hotel-body-left {
  width: 50%;
}
.hotel-overview .hotel-body-left h4 {
  color: #B98F4B;
  font-family: "Ruluko", Sans-serif;
  font-size: 23px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.hotel-overview .hotel-body-left p {
  text-align: justify;
  color: #BCA57F;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.hotel-body-right {
  width: 50%;
}

.hotel-body-image img {
  width: 100%;
}

.hotel-items-head h2 {
  text-align: center;
  color: #b98f4b;
  font-family: "Ruluko", Sans-serif;
  font-size: 31px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 2rem;
}

.hotel-items-box {
  display: flex;
  padding: 20px;
  gap: 2rem;
}

.hotel-item-left {
  width: 45%;
}
.hotel-item-left img {
  width: 100%;
}
.hotel-item-right {
  width: 55%;
}
.hotel-item-right h2 {
  text-align: start !important;
  color: #b98f4b;
  font-family: "Ruluko", Sans-serif;
  font-size: 23px;
  font-weight: 600;
  -webkit-text-stroke-color: #000;
  stroke: #000;
  margin: 0px 0px 20px 0px !important;
  padding: 0 !important;
}
.hotel-item-right p {
  text-align: justify;
  color: #bca57f;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}
.hotel-item-right span {
  display: flex;
  width: 78px;
    margin: 0 auto;
    margin-left: 0;
    align-items: center;
    margin-bottom: 20px;
}
.hotel-item-right span:before {
  display: block;
  content: "";
  border-block-end: 0;
  flex-grow: 1;
  border-block-start: 4.2px curved #bca57f;
}
.hotel-item-right span:before {
  width: 100%;
  min-height: 6.2px;
  mask-size: 18.4px 100%;
  mask-repeat: repeat-x;
  background-color: #BCA57F;
  mask-image: url("../../Assets/Hotels/download.svg");
}
.hotel-item-right span svg {
  color: #B7894E;
  font-weight: 900;
  font-size: 18px;
}

.hotel-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.hotel-button a {
  text-decoration: none;
}
.hotel-button a p {
  display: inline-block;
  line-height: 1;
  font-size: 15px;
  padding: 12px 40px;
  border-radius: 3px;
  text-align: center;
  transition: all .3s;
  font-family: "Ruluko", Sans-serif;
  font-weight: 500;
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0.3);
  fill: #000000;
  color: #000000;
  background-color: #bc9352;
}
.hotel-button a p:hover {
  background-color: #f8dea0;
  color: black;
}

.hotel-detail-item h2{
  text-align: center;
  color: #B98F4B;
    font-family: "Ruluko", Sans-serif;
    font-size: 23px;
    font-weight: 600;
    -webkit-text-stroke-color: #000;
    stroke: #000;
    padding-top: 30px;
    margin: 20px 0px;
}
.hotel-detail-item p{
  text-align: justify;
  color: #BCA57F;
  font-family: "Ruluko", Sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.hotel-contactForm{
  margin-top: 35px;
}
@media screen and (min-width: 1200px) {
  .hotel-detail-body,
  .hotel-detail-item {
    max-width: 1140px;
  }  
}

@media only screen and (max-width: 540px) {

  .hoteldetail-head h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .hotel-overview {
    flex-wrap: wrap;
  }

  .hotel-body-left{
    width: 100%;
  }

  .hotel-body-right{
    width: 100%;
  }

  .hotel-items-box{
    flex-wrap: wrap;
    padding: 0px 0px 2rem 0px;
    gap: 1.5rem;
  }

  .hotel-detail-item {
    padding: 10px 15px;
  }

  .hotel-item-left{
    width: 100%;
  }

  .hotel-item-right{
    width: 100%;
  }
  .hotel-detail-banner img {
    height: 30vh;
  }
}
