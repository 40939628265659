.people {
  width: 100%;
  background-color: #ffffff;
  padding: 4rem 0 2rem 0;
}

.people-main {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.people-main h1 {
  text-align: center;
  color: #3a3a3a;
  font-weight: 400;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}
.people-main h3 {
  text-align: center;
  font-size: 1.5rem;
  font-weight: inherit;
  color: #b98f4b;
  line-height: 1.3em;
  margin-bottom: 1.5rem;
}

.people-search {
  margin-bottom: 1.5rem;
}

.group {
  max-width: 370px;
  margin: 0 auto;
  padding-top: 0.5rem;
}

.group form {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
}

.people-search .input {
  width: 100%;
  height: 40px;
  line-height: 28px;
  padding: 25px 45px 25px 15px;
  border: 1px solid #e5e5e5;
  border-radius: 2px;
  outline: none;
  background-color: #fafafa;
  color: #0d0c22;
  transition: 0.3s ease;
}

.people-search .input::placeholder {
  color: #9e9ea7;
}

.people-search .input:focus,
.people-search input:hover {
  outline: none;
  border-color: rgba(247, 127, 0, 0.4);
  background-color: #fff;
  box-shadow: 0 0 0 4px rgb(247 127 0 / 10%);
}

.group svg {
  position: absolute;
  right: 1rem;
  fill: #414141;
  width: 1rem;
  height: 1rem;
}

@media screen and (min-width: 1200px) {
  .people-main {
    max-width: 1140px;
  }
}
