.footer{
    width: 100%;
    background-color: black;
    border-top: 1px solid #282626;
    font-family: "Ruluko", Sans-serif;
}

.footer-main{
    width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 3rem 15px 4rem 15px;
}

.footer-main{
    display: grid;
    grid-template-columns: 30% 30% 40%;
    gap: 10px;
}


.footer-main h3{
    font-size: 1rem;
    color: #b98f4b;
    margin-bottom: 8px;
}
.footer-main p{
    color: #ddc8ac;
    margin-bottom: 25px;
    font-size: 13px;
}
.footer-main p:last-child{
    color: #ddc8ac;
    margin-bottom: 0;
}

.footer-contact-address ul{
    padding: 0;
}
.footer-contact-address ul li{
    color: #ddc8ac;
    display: flex;
}

.footer-contact-detail ul{
   padding: 0;
}
.footer-contact-detail ul li{
    display: flex;
}
.footer-contact-detail ul li a{
    text-decoration: none;
    color: #ddc8ac;
    font-size: 14px;
}
.footer-contact-detail ul li a:hover{
    color: #3a3a3a;
}

.footer-contact-form{
    border-color: rgba(0, 0, 0, 0);
    background-color: #b98f4b;
    padding: 3rem 5rem;
}
.footer-contact-form{
    border-color: rgba(0, 0, 0, 0);
    background-color: #b98f4b;
    padding: 3rem 5rem;
}
.footer-contact-form h4{
   color: #333333;
   font-size: 22px;
    /* font-family: Roboto; */
    font-weight: 700;
    text-align: left;
}
.footer-contact-form p{
   color: #333333;
   font-size: 15px;
    /* font-family: Roboto; */
    font-weight: normal;
    text-align: left;
    margin: 10px 0;
}

.contact-form-box{
    display: grid;
    grid-template-columns: repeat(4,1fr);
    margin-right: -5px;
    margin-left: -5px;
}
.contact-form-boxes{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap :1rem;
}
.footer-main .contact-form-box{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    margin-right: -5px;
    margin-left: -5px;
}
.footer-main .footer-contact-form{
    border-color: rgba(0, 0, 0, 0);
    background-color: #b98f4b;
    padding: 3rem 2rem;
}

.contact-form-box .input-box{
    min-width: 0;
    flex: 1;
    padding: 0 5px;
}
.contact-form-box .input-box label{
    font-size: 13px;
    /* font-family: Roboto; */
    font-weight: normal;
    color: black;
}
.input-box label{
    font-size: 13px;
    /* font-family: Roboto; */
    font-weight: normal;
    color: black;
}

form .input-box{
    margin-bottom: 10px;
}
form .input-box input{
    border-color: #c4360f;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    border-radius: 3px;
    border-width: 1px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-width: unset;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    display: block;
    margin: 0;
    padding: 9px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background-image: unset;
    line-height: 1.3em;
    -webkit-transition: .2s ease;
    transition: .2s ease;
}
form .input-box select{
    border-color: #c4360f;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    border-radius: 3px;
    border-width: 1px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-width: unset;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    display: block;
    margin: 0;
    padding: 9px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background-image: unset;
    line-height: 1.3em;
    -webkit-transition: .2s ease;
    transition: .2s ease;
}
form .input-box textarea{
    border-color: #c4360f;
    background-color: #ffffff;
    color: #000000;
    font-size: 14px;
    border-radius: 3px;
    border-width: 1px;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-height: 140px;
    resize: vertical;
    display: block;
    margin: 0;
    padding: 9px;
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    background-image: unset;
    line-height: 20px;
    -webkit-transition: .2s ease;
    transition: .2s ease;
}

.contact-form-box-button{
    min-width: 0;
    flex: 1;
    padding: 0 5px;
}
.contact-form-box-button button p{
    margin: 0;
    padding: 0;
    border: 0;
}

.contact-form-box-button button{
    background-color: #894343;
    color: #FFFFFF;
    font-size: 14px;
    font-family: Roboto;
    font-weight: 500;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-decoration: none;
    width: auto;
    min-width: 100px;
    max-width: unset;
    height: auto;
    min-height: unset;
    max-height: unset;
    cursor: pointer;
    display: inline-block;
    position: relative;
    margin: 0 10px 0 0;
    padding: 10px;
    border: 0;
    border-radius: 2px;
    background-image: unset;
    line-height: 1.45em;
    text-align: center;
    -webkit-transition: .2s ease;
    transition: .2s ease;
}

.contact-form-box-button button p{
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.footer-copyright{
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background-image: none;
    
    border-style: solid;
    border-width: 0px;
    border-top-width: 1px;
    border-top-color: #131313;
    padding: 20px 0px;
}
.footer-copyright p{
     color: #bca57f;
     margin: 0;
}

@media screen and (min-width: 1200px) {
    .footer-main{
      max-width: 1200px;
    }
  }

  @media only screen and (max-width: 990px) {

    .footer-main {
    grid-template-columns: repeat(1,1fr);
    }

    .footer-contact-detail{
        padding-top: 2rem;
    }

    .footer-main h2 {
        margin-bottom: 1rem;
    }

    .footer-contact-address ul {
        margin-bottom: 2rem;
    }

    .footer-copyright p {
        min-height: 100%;
    }
    .contact-form-box{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        margin-right: -5px;
        margin-left: -5px;
    }
  }

  @media only screen and (max-width: 540px) {
    .contact-form-box{
        display: block;
    }
    .footer-contact-form{
        padding: 3rem 2rem;
    }
    .footer-contact-form :nth-child(4){
        display: flex;
    }
    .footer-main .contact-form-box{
        grid-template-columns: repeat(1,1fr);
    }
    .contact-form-box-button button{
        width: 100% !important;
    }
  }