.category-body1{
    width: 100%;
    background-color: #000000;
    padding: 4rem 0 2rem 0;
  }
  .category-body2{
    width: 100%;
    background-color: #111111;
    padding: 4rem 0 2rem 0;
  }
  
  .category-head{
      text-align: center;
  }
  .category-item-right a{
      text-decoration: none;
  }
  .category-head h1{
      color: #BCA57F;
      font-family: "Ruluko", Sans-serif;
      font-size: 27px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.3em;
      margin-bottom: 20px;
  }
  
  .category-head p{
      text-align: center;
      color: #BCA57F;
      font-family: "Ruluko", Sans-serif;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 20px;
  }
  
  .category-body-items{
      display: flex;
      gap: 2.5rem;
  }
  
  .category-item-left{
      width: 45%;
  }
  .category-item-left img{
      width: 100%;
  }
  
  .category-item-right{
      width: 55%;
  }
  .category-item-right h2{
      color: #B98F4B;
      font-family: "Ruluko", Sans-serif;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      -webkit-text-stroke-color: #000;
      stroke: #000;
      margin-bottom: 20px;
  }
  .category-item-right h4{
      color: #8aff74;
      font-family: "Ruluko", Sans-serif;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 20px;
  }
  .category-item-right .category-title p{
      color: #9e2c28;
      font-family: "Ruluko", Sans-serif;
      font-size: 17px;
      font-weight: 500;
      text-transform: none;
      line-height: 27px;
      margin-bottom: 20px;
  }
  .category-item-right p{
      text-align: justify;
      color: #BCA57F;
      font-family: "Ruluko", Sans-serif;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 20px;
  }
  .category-item-right .category-button{
      display: flex;
      align-items: center;
      justify-content: flex-start;
  }
  .category-item-right .category-button p{
      font-family: "Ruluko", Sans-serif;
      font-weight: 500;
      color: black;
      background-color: #bc9352;
      display: inline-block;
      /* line-height: 1; */
      font-size: 18px;
      padding: 12px 24px;
      border-radius: 3px;
      text-align: center;
      transition: all .3s;
  }
  .category-item-right .category-button p:hover {
    background-color: #f8dea0;
    color: black;
  }

  @media only screen and (max-width: 540px) {

    .category-head h1{
        font-size: 20px;
    }
    .category-body-items{
        flex-wrap: wrap;
    }

    .category-item-left{
        width: 100%;
    }

    .category-item-right{
        width: 100%;
    }

    .category-item-right a {
        width: 100%;
    }

    .category-item-right .category-button p {
        width: 100%;
    }
  }